import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
// routes
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';
import ProviderRoutes from './ProviderRoutes';

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    const account = useSelector((state) => state.account);
    const { user, isLoggedIn } = account;

    return (
        <Switch>
            <React.Fragment>
                {!isLoggedIn &&
                    <LoginRoutes />
                }
                {user && user.role === 'LOGISTICA' &&
                    <MainRoutes />
                }
                {user && user.role === 'ADMIN' &&
                    <AdminRoutes />
                }
                {user && user.role === 'PROVIDER' &&
                    <ProviderRoutes />
                }
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
