import { dashboard } from './dashboard';
import { logistic } from './logistic';
import { admin } from './admin';
import { provider } from './provider';

const menuItems = {
    itemsLogistic: [dashboard, logistic],
    itemsAdmin: [dashboard, ...admin],
    itemsProvider: [dashboard, provider]
};

export default menuItems;
