import React from 'react';
import Icon from '../assets/images/icon.png';

const IuiuIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            version="1.1"
            viewBox="0 0 1440 1440"
            xmlSpace="preserve"
        >
            <defs>
                <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
                    <path d="M0 1080h1080V0H0z"></path>
                </clipPath>
            </defs>
            <g transform="matrix(1.33333 0 0 -1.33333 0 1440)">
                <g>
                    <g clipPath="url(#clipPath18)">
                        <g transform="translate(248.931 380.433)">
                            <path
                                fill="#d5272a"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c3.542-4.431 8.418-7.092 14.175-7.092h553.774c5.312 0 10.634 2.66 14.175 7.092 3.542 4.431 4.432 10.189 3.106 15.51l-98.354 369.483c-7.528 27.469-32.337 46.957-60.696 46.957H155.927c-28.35 0-53.605-19.052-60.696-46.957L-3.123 15.51C-4.44 9.753-3.105 3.996-.008 0z"
                            ></path>
                        </g>
                        <g transform="translate(539.999 864.433)">
                            <path
                                fill="#d5272a"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c9.744 0 17.717 7.973 17.717 17.717v98.554c0 9.744-7.973 17.717-17.717 17.717s-17.717-7.973-17.717-17.717V17.717C-17.717 7.973-9.744 0 0 0"
                            ></path>
                        </g>
                        <g transform="translate(439.006 777.85)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c-31.679 0-59.222-21.316-66.962-51.77l-19.669-73.892a2.689 2.689 0 012.599-3.38h7.85c1.218 0 2.284.819 2.598 1.997l19.168 72.013C-48.119-30.263-25.743-12.965 0-12.965h61.903a2.69 2.69 0 012.689 2.689v7.587A2.69 2.69 0 0161.903 0z"
                            ></path>
                        </g>
                        <g transform="translate(911.494 81.579)">
                            <path
                                fill="#3d65ab"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0h-742.539a6.852 6.852 0 00-6.852 6.852v242.619a6.852 6.852 0 006.852 6.852H0a6.852 6.852 0 006.852-6.852V6.852A6.852 6.852 0 000 0"
                            ></path>
                        </g>
                        <path
                            fill="#fff"
                            fillOpacity="1"
                            fillRule="nonzero"
                            stroke="none"
                            d="M329.852 243.926h35.206V122.313h-35.206zm-3.099 37.496c0 5.621 2.024 10.456 6.074 14.505 4.048 4.048 8.883 6.076 14.503 6.076 5.703 0 10.601-2.004 14.691-6.012 4.091-4.011 6.135-8.866 6.135-14.569 0-5.699-2.044-10.579-6.135-14.627-4.09-4.048-8.988-6.072-14.691-6.072-5.62 0-10.455 2.064-14.503 6.198-4.05 4.129-6.074 8.964-6.074 14.501"
                        ></path>
                        <g transform="translate(435.47 243.926)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0v-63.966c0-10.745 2.087-18.431 6.261-23.06 4.172-4.628 10.972-6.944 20.392-6.944 9.34 0 16.115 2.337 20.332 7.005 4.214 4.669 6.321 12.335 6.321 22.999V0h35.332v-63.966c0-21.409-5.001-37.171-15.002-47.295-10-10.125-25.495-15.187-46.486-15.187-21.406 0-37.128 5.042-47.169 15.126-10.043 10.079-15.063 25.866-15.063 47.356V0z"
                            ></path>
                        </g>
                        <path
                            fill="#fff"
                            fillOpacity="1"
                            fillRule="nonzero"
                            stroke="none"
                            d="M559.438 243.926h35.206V122.313h-35.206zm-3.099 37.496c0 5.621 2.024 10.456 6.075 14.505 4.048 4.048 8.883 6.076 14.502 6.076 5.703 0 10.601-2.004 14.692-6.012 4.091-4.011 6.135-8.866 6.135-14.569 0-5.699-2.044-10.579-6.135-14.627-4.091-4.048-8.989-6.072-14.692-6.072-5.619 0-10.454 2.064-14.502 6.198-4.051 4.129-6.075 8.964-6.075 14.501"
                        ></path>
                        <g transform="translate(665.057 243.926)">
                            <path
                                fill="#fff"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0v-63.966c0-10.745 2.087-18.431 6.261-23.06 4.171-4.628 10.972-6.944 20.392-6.944 9.34 0 16.115 2.337 20.332 7.005 4.214 4.669 6.321 12.335 6.321 22.999V0h35.332v-63.966c0-21.409-5.001-37.171-15.002-47.295-10.001-10.125-25.495-15.187-46.486-15.187-21.406 0-37.128 5.042-47.169 15.126-10.043 10.079-15.063 25.866-15.063 47.356V0z"
                            ></path>
                        </g>
                        <g transform="translate(770.668 811.806)">
                            <path
                                fill="#d5272a"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c6.89-6.89 18.165-6.89 25.055 0l69.689 69.689c6.89 6.889 6.889 18.165 0 25.055-6.89 6.889-18.166 6.889-25.055 0L0 25.055C-6.89 18.165-6.89 6.89 0 0"
                            ></path>
                        </g>
                        <g transform="translate(308.448 811.806)">
                            <path
                                fill="#d5272a"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c6.89 6.89 6.89 18.165 0 25.055l-69.689 69.689c-6.889 6.889-18.165 6.889-25.055 0-6.889-6.89-6.889-18.166 0-25.055L-25.055 0C-18.165-6.89-6.89-6.89 0 0"
                            ></path>
                        </g>
                        <g transform="translate(866.954 580.684)">
                            <path
                                fill="#d5272a"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c0-9.744 7.973-17.717 17.717-17.717h98.554c9.744 0 17.717 7.973 17.717 17.717s-7.973 17.717-17.717 17.717H17.717C7.973 17.717 0 9.744 0 0"
                            ></path>
                        </g>
                        <g transform="translate(79.058 580.684)">
                            <path
                                fill="#d5272a"
                                fillOpacity="1"
                                fillRule="nonzero"
                                stroke="none"
                                d="M0 0c0-9.744 7.973-17.717 17.717-17.717h98.554c9.744 0 17.717 7.973 17.717 17.717s-7.973 17.717-17.717 17.717H17.717C7.973 17.717 0 9.744 0 0"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

const MediRapidIcon = ({ width, height }) => {
    return <img src={Icon} alt="icon" style={{ height, width }} />;
};

const Logo = () => <MediRapidIcon height="128px" width="100%" />;
const Logo2 = () => <MediRapidIcon height="43px" width="100%" />;

export { Logo, Logo2 };
