import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const RequestOxygen = Loadable(lazy(() => import('../views/pages/app/RequestOxygen')));
//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    return (
        <Route>
            <MainLayout>
                <Switch>
                    <AuthGuard>
                        <Route path="/dashboard" component={DashboardDefault} />
                        <Route path="/solicitudes-oxigeno" component={RequestOxygen} />
                        <Route path="/emergency-oxygen" component={() => <div />} />
                        <Redirect from="/" to="/dashboard" />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
