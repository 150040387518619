import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import Ratings from '../views/pages/admin/Ratings';
import AmbulanceProgress from '../views/pages/admin/Ambulance/AmbulanceProgress';
import AmbulanceHistory from '../views/pages/admin/Ambulance/AmbulanceHistory';
import AmbulanceProgramer from '../views/pages/admin/Ambulance/AmbulanceProgramer';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const Products = Loadable(lazy(() => import('../views/pages/admin/Products')));
const AddNurse = Loadable(lazy(() => import('../views/pages/admin/AddNurse')));
const RequestOxygen = Loadable(lazy(() => import('../views/pages/app/RequestOxygen')));
const Provider = Loadable(lazy(() => import('../views/pages/admin/Provider')));
const BuyProduct = Loadable(lazy(() => import("../views/pages/app/BuyProducts")));

const MainRoutes = () => {

    return (
        <Route>
            <MainLayout>
                <Switch>
                    <AuthGuard>
                        <Route path="/dashboard" component={DashboardDefault} />
                        <Route path="/productos" component={Products} />
                        <Route path="/add-nurse" component={AddNurse} />
                        <Route path="/solicitudes-oxigeno" component={RequestOxygen} />
                        <Route path="/compra-mobiliario" component={BuyProduct} />
                        <Route path="/proveedores" component={Provider} />
                        <Route path="/proveedores-calificaciones" component={Ratings} />
                        <Route path="/ambulances-progress" component={AmbulanceProgress} />
                        <Route path="/ambulances-history" component={AmbulanceHistory} />
                        <Route path="/ambulances-programer" component={AmbulanceProgramer} />
                        <Redirect from="/" to="/dashboard" />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
