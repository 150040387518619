import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import Routes from './routes';
import theme from './themes';
import NavigationScroll from './layout/NavigationScroll';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({
    uri: false ? 'http://127.0.0.1:4001/graphql' : 'https://api.iuiu.mx/graphql',
});

const authLink = setContext(async (_, { headers }) => {
	const token = localStorage.getItem('token');
	return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
	}
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only'
        }
    }
});

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </ApolloProvider>
    );
};

export default App;
