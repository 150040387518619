import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import RequestOxygen from '../views/pages/app/RequestOxygen';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const Products = Loadable(lazy(() => import('../views/pages/admin/Products')));
const BuyProduct = Loadable(lazy(() => import("../views/pages/app/BuyProducts")));
//-----------------------|| MAIN ROUTING ||-----------------------//

const ProviderRoutes = () => {
    return (
        <Route>
            <MainLayout>
                <Switch>
                    <AuthGuard>
                        <Route path="/dashboard" component={DashboardDefault} />
                        <Route path="/productos" component={Products} />
                        <Route path="/solicitudes-oxigeno" component={RequestOxygen} />
                        <Route path="/compra-mobiliario" component={BuyProduct} />
                        <Redirect from="/" to="/dashboard" />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default ProviderRoutes;
