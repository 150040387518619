import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { 
    Container, 
    Grid, 
} from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import { gridSpacing } from '../../../../store/constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';

const columns = [
  { id: 'id', label: 'ID', minWidth: 100 },
  { id: 'type', label: 'Tipo', minWidth: 100 },
  { id: 'dateProgramer', label: 'Fecha programación', minWidth: 100 },
  { id: 'patient', label: 'Paciente', minWidth: 100 },
  { id: 'customer', label: 'Cliente', minWidth: 100 },
  { id: 'address', label: 'Direccion cliente', minWidth: 200 },
  { id: 'hospital', label: 'Direccion hospital', minWidth: 200 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    position: 'absolute',
    background: '#FFF',
    borderRadius: 5,
    maxWidth: 750, 
    marginTop: 20,
    padding: 20,
  },
  modal: {
    overflow: 'scroll',
    boxSizing: 'content-box'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  }
}));

const QUERY_GET_AMBULANCES_IN_PROGRESS = gql`
    query ambulancesForStatus($status: AmbulanceStatus!, $type: String){
        ambulancesForStatus(status: $status, type: $type){
            id
            customer{
                id
                name
            }
            address{
                id
                fullAddress
                coordinates{
                    lat
                    lng
                }
                codePostal
            }
            type
            dateProgramer
            patient
            hospital{
                codePostal
                fullAddress
                coordinates{
                    lat
                    lng
                }
            }
            createAt
            status
        }
    }
`;

export default function AmbulanceProgramer() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [ambulances, setAmbulances] = useState([]);

    const [getAmbulancesInProgress] = useLazyQuery(QUERY_GET_AMBULANCES_IN_PROGRESS, {
        variables: { status: 'PENDIENTE', type: 'PROGRAMADO' },
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if(data.ambulancesForStatus){
                setAmbulances(data.ambulancesForStatus)
            }
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    useEffect(() => {
        getAmbulancesInProgress()
    },[])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
            />
            <Grid container spacing={gridSpacing}>
                <Grid item xs={6} sm={6}>
                    <MuiTypography variant="h2" gutterBottom>
                        Ambulancias programadas
                    </MuiTypography>
                </Grid>
            </Grid>
            <MainCard content style={{marginTop: 30}}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={'center'} 
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ambulances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    <TableCell align={'center'} >
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {row.type}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {moment(row.dateProgramer).format('DD, MM YYYY hh:mm A')}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {row.patient}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {row.customer.name}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {row.address.fullAddress}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {row.hospital.fullAddress}
                                                    </TableCell>
                                                    
                                                </TableRow>
                                            );
                                        })}
                                        {ambulances.length === 0 &&
                                            <TableRow hover role="checkbox" tabIndex={-1} key={'000'}>
                                                <TableCell key={'0001'} align={'center'} colSpan={11}>
                                                    Sin datos que mostrar
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={ambulances.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
}