import React from 'react';
import { useDispatch } from 'react-redux';

import {
    Typography
} from '@material-ui/core';
import { LOGOUT } from './../../../../store/actions';

const ProfileSection = () => {
    const dispatcher = useDispatch();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleLogout = () => {
        localStorage.clear()
        dispatcher({ type: LOGOUT });
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <React.Fragment>
            <Typography style={{cursor: 'pointer'}} onClick={handleLogout}>Salir</Typography>
            
        </React.Fragment>
    );
};

export default ProfileSection;
