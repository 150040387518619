import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const AuthRegister = Loadable(lazy(() => import('../views/pages/authentication/register')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    return (
        <Route>
            <Switch>
                <NavMotion>
                    <GuestGuard>
                        <Route path="/login" component={AuthLogin} />
                        <Route path="/register" component={AuthRegister} />
                        <Redirect from='/' to="/login" />
                    </GuestGuard>
                </NavMotion>
            </Switch>
        </Route>
    );
};

export default LoginRoutes;
