import { IconClipboardList, IconUrgent } from '@tabler/icons';

const icons = {
    IconClipboardList: IconClipboardList,
    IconUrgent: IconUrgent
};

export const admin = [
    {
        id: 'sample-docs-roadmap',
        type: 'group',
        title: 'Oxígeno',
        children: [
            {
                id: 'add-oxygen-page',
                title: 'Administrar productos',
                type: 'item',
                url: '/productos',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            },
            {
                id: 'request-oxygen-page',
                title: 'Solicitudes de oxígeno',
                type: 'item',
                url: '/solicitudes-oxigeno',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'sample-nurse-roadmap',
        type: 'group',
        title: 'Enfermeras',
        children: [
            {
                id: 'add-nurse-page',
                title: 'Agregar enfermeras',
                type: 'item',
                url: '/add-nurse',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            },
            // {
            //     id: 'request-nurse-page',
            //     title: 'Solicitudes de enfermeras',
            //     type: 'item',
            //     url: '/request-nurse',
            //     icon: icons['IconClipboardList'],
            //     breadcrumbs: false
            // }
        ]
    },
    {
        id: 'sample-proveedor-roadmap',
        type: 'group',
        title: 'Proveedor',
        children: [
            {
                id: 'add-proveedor-page',
                title: 'Agregar proveedores',
                type: 'item',
                url: '/proveedores',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            },
            {
                id: 'add-proveedor-rating-page',
                title: 'Calificaciones',
                type: 'item',
                url: '/proveedores-calificaciones',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'sample-ambulance-roadmap',
        type: 'group',
        title: 'Ambulancias',
        children: [
            {
                id: 'progress-ambulance-page',
                title: 'En curso',
                type: 'item',
                url: '/ambulances-progress',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            },
            {
                id: 'ambulance-archive-page',
                title: 'Historial',
                type: 'item',
                url: '/ambulances-history',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            },
            {
                id: 'ambulance-next-page',
                title: 'Programadas',
                type: 'item',
                url: '/ambulances-programer',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'sample-mobiliario-roadmap',
        type: 'group',
        title: 'Mobiliario',
        children: [
            {
                id: 'progress-mobiliario-page',
                title: 'Mobiliario',
                type: 'item',
                url: '/compra-mobiliario',
                icon: icons['IconClipboardList'],
                breadcrumbs: false
            },
        ]
    }
];
