import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
    Container,
    Button,
    Grid,
    Modal,
    Card,
    MenuItem,
    Menu,
    IconButton,
    CardMedia,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MuiTypography from '@material-ui/core/Typography';
// project imports
import MainCard from './../../../ui-component/cards/MainCard';
import { gridSpacing } from './../../../store/constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useSelector } from 'react-redux';

const columns = [
    { id: 'code', label: 'No. pedido', minWidth: 150 },
    { id: 'customer.name', label: 'Cliente', minWidth: 150 },
    { id: 'last_name', label: 'Renta/Venta', minWidth: 100 },
    { id: 'gender', label: 'Horario solicitud', minWidth: 100 },
    { id: 'age', label: 'Ubicación', minWidth: 100 },
    { id: 'articulos', label: 'Articulos', minWidth: 100 },
    { id: 'total', label: 'Total', minWidth: 50 },
    { id: 'metodo de pago', label: 'Metodo de pago', minWidth: 120 },
    { id: 'status', label: 'Estado', minWidth: 50 },
    { id: 'documentos', label: 'Documentos cargados', minWidth: 30 },
    { id: 'cuestionario', label: 'Cuestionario', minWidth: 30 },
    { id: 'provider', label: 'Proveedor', minWidth: 30 },
    { id: 'addPrice', label: 'Precio adicional', minWidth: 30 },
    { id: 'acciones', label: 'Acciones', minWidth: 100 }
];

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#FFF',
        borderRadius: 5,
        minWidth: 750,
        margin: 'auto',
        padding: 20,
        marginTop: 50,
        marginBottom: 50
    },
    modal: {
        overflow: 'scroll',
        boxSizing: 'content-box'
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    }
});

const MOD_PLUS_PRICE_AND_MOD_PROVIDER = gql`
    mutation modProviderAndPlusPrice($id: ID!, $provider: ID!, $pricePlus: String) {
        modProviderAndPlusPrice(id: $id, provider: $provider, pricePlus: $pricePlus)
    }
`;

const QUERY_GET_PROVIDERS = gql`
    query providers {
        providers {
            id
            name
        }
    }
`;

const QUERY_REQUEST_OXYGEN = gql`
    query allRents {
        allRents {
            id
            code
            paymentMethod
            productType
            amount
            recurrency
            schedule
            products
            status
            createAt
            deliveries
            isSale
            utils {
                name
                price
            }
            customer {
                id
                name
                email
            }
            paymentCard {
                id
            }
            address {
                id
                fullAddress
            }
            oxygenQuestion {
                id
                other
                prescribedOxygen
                prescribedFlow
                frecuency
                deviceMeasuringSaturation
                saturation
            }
            documents
            provider {
                id
                name
            }
            pricePlus
            paymentStatus
        }
    }
`;

const MUTATION_SEND_DELIVERE = gql`
    mutation deliverieRent($id: ID!, $status: String!, $schedule: String!, $statusRent: String) {
        deliverieRent(input: { id: $id, status: $status, schedule: $schedule, statusRent: $statusRent }) {
            id
        }
    }
`;

const MUTATION_NOTIFICATION_RUN = gql`
    mutation notificationInRun($id: ID!) {
        notificationInRun(id: $id) {
            id
        }
    }
`;

const MUTATION_CHANGE_PAYMENT_STATUS = gql`
    mutation changePaymentStatus($id: ID!) {
        changePaymentStatus(id: $id) {
            id
        }
    }
`;

const MenuAction = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleCloseAnchorEl = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton
                aria-controls={'menu-actions-' + props.id}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={'menu-actions-' + props.id}
                anchorEl={anchorEl}
                keepMounted={false}
                open={Boolean(anchorEl)}
                onClose={handleCloseAnchorEl}
            >
                {props.paymentMethod !== 'CREDITCARD' && !props.paymentStatus && (
                    <MenuItem onClick={() => props.submitPayment(props.id)}>Marcar como saldado el pago</MenuItem>
                )}
                <MenuItem onClick={() => props.setNotificationInRun({ variables: { id: props.id } })}>Notificar envío</MenuItem>
                <MenuItem onClick={() => props.submit(props.id, 'ENTREGADO', 'ATENDIDO')}>Marcar como atendido</MenuItem>
                <MenuItem onClick={() => props.modProvider()}>Modificar proveedor y/o precio adicional</MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default function RequestOxygen() {
    const [open, setOpen] = React.useState(false);
    const [openQuestion, setOpenQuestion] = useState(false);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [oxygensRents, setOxygensRents] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [question, setQuestion] = useState({});
    const [providers, setProviders] = useState([]);
    const [provider, setProvider] = useState('');
    const [rows, setRows] = useState([]);
    const [openModalProvider, setOpenModalProvider] = useState(false);

    const [providerMod, setProviderMod] = useState('');
    const [pricePlus, setPricePlus] = useState('');
    const [idRent, setIdRent] = useState('');
    const [waiting, setWaiting] = useState(false);

    const account = useSelector((state) => state.account);
    const { user } = account;

    const [modProviderAndPricePlus] = useMutation(MOD_PLUS_PRICE_AND_MOD_PROVIDER, {
        onCompleted: (data) => {
            if (data.modProviderAndPlusPrice) {
                setWaiting(false);
                setOpenModalProvider(false);
                toast.success('Modificado con exito!');
                getAllProviders();
                getRequestOxygen();
            }
        },
        onError: (err) => {
            setWaiting(false);
            setOpenModalProvider(false);
        }
    });

    const [getAllProviders] = useLazyQuery(QUERY_GET_PROVIDERS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.providers) {
                setProviders(data.providers);
            }
        }
    });

    const [getRequestOxygen] = useLazyQuery(QUERY_REQUEST_OXYGEN, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            console.log(data, 'aqui?');
            if (data.allRents) {
                setRows(data.allRents);
                setOxygensRents(data.allRents);
            }
        },
        onError: (error) => {
            console.log(error, 'solicitudes');
        }
    });

    const [setDeliverieOxygen] = useMutation(MUTATION_SEND_DELIVERE, {
        onCompleted: (data) => {
            if (data.deliverieRent) {
                toast.success('Entrega realizada con exito!');
                getRequestOxygen();
            }
        },
        onError: (error) => {
            toast.error('Ocurrio algo inesperado');
        }
    });

    const [setNotificationInRun] = useMutation(MUTATION_NOTIFICATION_RUN, {
        onCompleted: (data) => {
            if (data.notificationInRun) {
                toast.success('Notificación enviada!');
                getRequestOxygen();
            }
        },
        onError: (error) => {
            toast.error('Ocurrio algo inesperado');
        }
    });

    const [setChangePaymentStatus] = useMutation(MUTATION_CHANGE_PAYMENT_STATUS, {
        onCompleted: (data) => {
            if (data.changePaymentStatus) {
                toast.success('Confirmacion de pago exitosa!');
                getRequestOxygen();
            }
        },
        onError: (error) => {
            toast.error('Ocurrio algo inesperado');
        }
    });

    useEffect(() => {
        getRequestOxygen();
        getAllProviders();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submit = (id, status, statusRent) => {
        setDeliverieOxygen({ variables: { id: id, status, schedule: moment().toISOString(), statusRent } });
    };

    const submitPayment = (id) => {
        setChangePaymentStatus({ variables: { id } });
    };

    const formatCurrency = (number) => {
        return new Intl.NumberFormat('es-MX').format(number);
    };

    const onFilterProvider = (value) => {
        setProvider(value);
        if (value !== '') setOxygensRents([...rows.filter((p) => p?.provider && p?.provider?.id === value)]);
        else setOxygensRents([...rows]);
    };

    return (
        <Container>
            <ToastContainer position="top-right" autoClose={5000} />
            <Grid container spacing={gridSpacing} justifyContent="space-between">
                <Grid item xs={6} sm={6}>
                    <MuiTypography variant="h2" gutterBottom>
                        Solicitudes de oxígeno
                    </MuiTypography>
                </Grid>
                {providers.length > 0 && (
                    <Grid item xs={3} sm={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="input-label-provider">Proveedores</InputLabel>
                            <Select
                                labelId="input-label-provider"
                                id="demo-simple-select-outlined"
                                defaultValue={provider}
                                onChange={(e) => onFilterProvider(e.target.value)}
                                label="Proveedores"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {providers.map((item) => (
                                    <MenuItem key={Math.random()} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <MainCard content style={{ marginTop: 30 }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} align={'center'} style={{ minWidth: column.minWidth }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {oxygensRents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align={'center'}>{row.code}</TableCell>
                                                    <TableCell align={'center'}>{row.customer.name}</TableCell>
                                                    <TableCell align={'center'}>
                                                        {row.isSale ? (
                                                            <div>Compra</div>
                                                        ) : (
                                                            <div>
                                                                Renta{' '}
                                                                {row.recurrency === 'Monthly'
                                                                    ? 'Mensual'
                                                                    : row.recurrency === 'Fortnightly'
                                                                    ? 'Quincenal'
                                                                    : row.recurrency === 'Weekly'
                                                                    ? 'Semanal'
                                                                    : 'de Recarga'}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        {moment(row.createAt).format('DD, MMM YYYY hh:mm A')}
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        {row?.address ? row?.address?.fullAddress : 'Sin dirección'}
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        {row.products && row.products.length > 0 && (
                                                            <>
                                                                {row.products.map((pro) => (
                                                                    <p style={{ fontSize: 15, color: '#000', fontWeight: 'bold' }}>
                                                                        * {pro.name}
                                                                    </p>
                                                                ))}
                                                            </>
                                                        )}
                                                        {row.utils && row.utils.length > 0 && (
                                                            <>
                                                                {row.utils.map((util) => (
                                                                    <p>* {util.name}</p>
                                                                ))}
                                                            </>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align={'center'}>${formatCurrency(row.amount)}</TableCell>
                                                    <TableCell align={'center'}>
                                                        {row.paymentMethod === 'CREDITCARD' ? 'Tarjeta de credito' : 'Contra entrega'}
                                                    </TableCell>
                                                    <TableCell align={'center'}>{row.status}</TableCell>
                                                    <TableCell align={'center'}>
                                                        <Button
                                                            onClick={() => {
                                                                setDocuments(row.documents);
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            Ver
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        <Button
                                                            onClick={() => {
                                                                setQuestion(row.oxygenQuestion);
                                                                setOpenQuestion(true);
                                                            }}
                                                        >
                                                            Ver
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        {row?.provider ? row?.provider?.name : 'Sin proveedor asignado'}
                                                    </TableCell>
                                                    <TableCell align={'center'}>{row.pricePlus ? row.pricePlus : 0}</TableCell>
                                                    <TableCell align={'center'} style={{ position: 'relative' }}>
                                                        <MenuAction
                                                            {...row}
                                                            modProvider={() => {
                                                                setIdRent(row.id);
                                                                setOpenModalProvider(true);
                                                            }}
                                                            admin={user?.role === 'ADMIN'}
                                                            submit={submit}
                                                            setNotificationInRun={setNotificationInRun}
                                                            submitPayment={submitPayment}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {oxygensRents.length === 0 && (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={'000'}>
                                                <TableCell key={'0001'} align={'center'} colSpan={11}>
                                                    Sin datos que mostrar
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={oxygensRents.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </MainCard>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Card>
                        <MuiTypography variant="h3" gutterBottom>
                            Documentos cargados
                        </MuiTypography>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} sm={12}>
                                <MuiTypography variant="h5" textAlign={'center'} gutterBottom>
                                    Documentos de identificacion
                                </MuiTypography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Card style={{ borderColor: '#D1D1D1', borderStyle: 'solid', borderWidth: 1, borderRadius: 5 }}>
                                    <CardMedia className={classes.media} image={documents.length ? documents[0] : ''} title="documentos" />
                                </Card>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Card style={{ borderColor: '#D1D1D1', borderStyle: 'solid', borderWidth: 1, borderRadius: 5 }}>
                                    <CardMedia className={classes.media} image={documents.length ? documents[1] : ''} title="documentos" />
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <MuiTypography variant="h5" textAlign={'center'} gutterBottom>
                                    Domicilio
                                </MuiTypography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Card style={{ borderColor: '#D1D1D1', borderStyle: 'solid', borderWidth: 1, borderRadius: 5 }}>
                                    <CardMedia className={classes.media} image={documents.length ? documents[2] : ''} title="documentos" />
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={3}>
                            <Grid item xs={12} sm={12} textAlign={'center'}>
                                <Button variant="contained" onClick={() => setOpen(false)} color="error">
                                    Cerrar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Modal>
            <Modal
                className={classes.modal}
                open={openQuestion}
                onClose={setOpenQuestion}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Card>
                        <MuiTypography variant="h3" gutterBottom>
                            Cuestionario de oxígeno
                        </MuiTypography>
                        <Grid container spacing={gridSpacing} mt={2}>
                            <Grid item xs={6} sm={6}>
                                ¿El oxígeno suplementario fue recetado por un profesional de la salud?
                            </Grid>
                            <Grid item xs={6} sm={6} textAlign="right">
                                {question.prescribedOxygen ? 'Si' : 'No'}
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={1}>
                            <Grid item xs={6} sm={6}>
                                Flujo recetado
                            </Grid>
                            <Grid item xs={6} sm={6} textAlign="right">
                                {question.prescribedFlow ? question.prescribedFlow + ' Lts por mts' : '0 Lts por mts'}
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={1}>
                            <Grid item xs={6} sm={6}>
                                Frecuencia
                            </Grid>
                            <Grid item xs={6} sm={6} textAlign="right">
                                {question.frecuency ? (question.frecuency === 'other' ? question.other : question.frecuency) : ''}
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={1}>
                            <Grid item xs={6} sm={6}>
                                ¿Cuenta con algún dispositivo para medir la saturación de oxígeno?
                            </Grid>
                            <Grid item xs={6} sm={6} textAlign="right">
                                {question.deviceMeasuringSaturation ? 'Si' : 'No'}
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={1}>
                            <Grid item xs={6} sm={6}>
                                Saturación
                            </Grid>
                            <Grid item xs={6} sm={6} textAlign="right">
                                {question.saturation ? question.saturation : 'Sin datos'}
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={3}>
                            <Grid item xs={12} sm={12} textAlign={'center'}>
                                <Button variant="contained" onClick={() => setOpenQuestion(false)} color="error">
                                    Cerrar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Modal>

            <Modal
                className={classes.modal}
                open={openModalProvider}
                onClose={setOpenModalProvider}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <Card>
                        <MuiTypography variant="h3" gutterBottom>
                            Modificar proveedor y/o precio adicional
                        </MuiTypography>
                        <Grid container spacing={gridSpacing} mt={3}>
                            <Grid item xs={6} sm={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="input-label-tyipe-producto">Proveedor</InputLabel>
                                    <Select
                                        labelId="input-label-tyipe-producto"
                                        id="demo-simple-select-outlined"
                                        value={providerMod}
                                        onChange={(e) => setProviderMod(e.target.value)}
                                        label="Proveedor"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {providers.map((p) => (
                                            <MenuItem value={p.id}>{p.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-name-student">Precio adicional</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-name-student"
                                        type="text"
                                        value={pricePlus}
                                        name="pricePlus"
                                        onChange={(e) => setPricePlus(e.target.value)}
                                        label="Precio adicional"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} mt={3}>
                            <Grid item xs={12} sm={12} textAlign={'center'}>
                                <Button
                                    variant="contained"
                                    disabled={waiting}
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        setWaiting(true);
                                        modProviderAndPricePlus({ variables: { id: idRent, provider: providerMod, pricePlus } });
                                    }}
                                    color="primary"
                                >
                                    Guardar
                                </Button>
                                <Button variant="contained" onClick={() => setOpenModalProvider(false)} color="error">
                                    Cerrar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Modal>
        </Container>
    );
}
