import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { 
    Container, 
    Grid, 
    Avatar,
} from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
// project imports
import MainCard from '../../../ui-component/cards/MainCard';
import { gridSpacing } from '../../../store/constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { gql, useLazyQuery } from '@apollo/client';

const columns = [
  { id: 'name', label: 'Proveedor', minWidth: 100 },
  { id: 'p_rating', label: 'Promedio calificacion', minWidth: 200 },
  { id: 'pm_rating', label: 'No. Calificaciones', minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    position: 'absolute',
    background: '#FFF',
    borderRadius: 5,
    maxWidth: 750, 
    marginTop: 20,
    padding: 20,
  },
  modal: {
    overflow: 'scroll',
    boxSizing: 'content-box'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  }
}));

const QUERY_GET_RATINGS = gql`
    query getAllRatings{
        getAllRatings{
            score
            memory
            provider{
                name
            }
        }
    }
`;

export default function Ratings() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [ratings, setRatings] = useState([]);

    const [getRatings] = useLazyQuery(QUERY_GET_RATINGS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if(data.getAllRatings){
                setRatings(data.getAllRatings)
            }
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    useEffect(() => {
        getRatings()
    },[])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
            />
            <Grid container spacing={gridSpacing}>
                <Grid item xs={6} sm={6}>
                    <MuiTypography variant="h2" gutterBottom>
                        Calificaciones de proveedores
                    </MuiTypography>
                </Grid>
            </Grid>
            <MainCard content style={{marginTop: 30}}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={'center'} 
                                                style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ratings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={Math.random()}>
                                                    <TableCell align={'center'} >
                                                        {row.provider?.name}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {(row.score / row.memory)}
                                                    </TableCell>
                                                    <TableCell align={'center'} >
                                                        {row.memory}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {ratings.length === 0 &&
                                            <TableRow hover role="checkbox" tabIndex={-1} key={'000'}>
                                                <TableCell key={'0001'} align={'center'} colSpan={11}>
                                                    Sin datos que mostrar
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={ratings.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
}