// assets
import { IconClipboardList, IconUrgent } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList: IconClipboardList,
    IconUrgent: IconUrgent
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const provider = {
    id: 'sample-docs-roadmap',
    type: 'group',
    title: 'Productos',
    children: [
        {
            id: 'products-page',
            title: 'Catalogo de productos',
            type: 'item',
            url: '/productos',
            icon: icons['IconClipboardList'],
            breadcrumbs: false
        },
        {
            id: 'request-oxygen-page',
            title: 'Solicitudes de oxígeno',
            type: 'item',
            url: '/solicitudes-oxigeno',
            icon: icons['IconClipboardList'],
            breadcrumbs: false
        },
        {
            id: 'progress-mobiliario-page',
            title: 'Mobiliario',
            type: 'item',
            url: '/compra-mobiliario',
            icon: icons['IconClipboardList'],
            breadcrumbs: false
        },
    ]
};
